import React from "react";
import { gql, useQuery } from "@apollo/client";
import debounce from "lodash/fp/debounce";
import uniqBy from "lodash/fp/uniqBy";
import { omitUndefined } from "@/components/utils";
import { Configuration } from "@/components/utils/constants";

const SORT_COLUMNS = {
  ID: "ID",
  NAME: "NAME",
  CREATED: "CREATED",
  DEFAULT: "DEFAULT",
};

const SORT_ORDER = {
  ASC: "ASC",
  DESC: "DESC",
};

const SORT_VIEWS = (t) => [
  {
    name: t("alphabetical"),
    sort: {
      column: SORT_COLUMNS.NAME,
      order: SORT_ORDER.ASC,
    },
  },
  {
    name: t("date-created"),
    sort: {
      column: SORT_COLUMNS.CREATED,
      order: SORT_ORDER.DESC,
    },
  },
];

const getSortFromColumn = (views, column) => {
  return views.find(view => view.sort.column === column);
};

const getSortFromName = (views, name) => {
  return views.find(view => view.name === name);
};

const MY_ORGANIZATIONS_SEARCH_QUERY = gql`
  query relayOrganizationSearch(
    $userId: ID
    $organizationId: ID
    $search: OrganizationSearchInput
    $sort: [OrganizationSort!]
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    organizationSearch(
      userId: $userId,
      organizationId: $organizationId,
      search: $search,
      sort: $sort,
      first: $first,
      after: $after,
      last: $last,
      before: $before,
    ) {
      type
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
        total
        onPage
      }
      edges {
        node {
          ... on OrganizationNode {
            organization {
              id
              bannerLogo
              logo
              name
              created
              callerCanManage
              counts {
                hierarchyMembers
                hierarchyTeams
              },
              organizationReportsSetting: configurationSetting(configurationId: "${Configuration.ORGANIZATION_REPORTS}") {
                enabled
              }
              plgPlan {
                trialDaysLeft
                trialStartDate
                trialEndDate
                derivedStatus
                cloverleafTier
                promotion {
                  user {
                    fullName
                  }
                  promotion {
                    label
                    promotionId
                  }
                }
              }
              trustedUsers {
                user {
                  id
                  avatar
                  fullName
                }
              }
              companyImpression(callerOrganizationId: $organizationId) {
                id
                name
                employeeCountBucket
                domain
                contactEmail
              }
            }
          }
        }
      }
    }
  }
`;

const ADMIN_ORGANIZATIONS_SEARCH_QUERY = gql`
  query relayOrganizationSearch(
    $userId: ID
    $organizationId: ID
    $search: OrganizationSearchInput
    $sort: [OrganizationSort!]
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    organizationSearch(
      userId: $userId,
      organizationId: $organizationId,
      search: $search,
      sort: $sort,
      first: $first,
      after: $after,
      last: $last,
      before: $before,
    ) {
      type
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
        total
        onPage
      }
      edges {
        node {
          ... on OrganizationNode {
            organization {
              id
              name
              alias
              description
              isTopParentOrganization
              plgPlan {
                cloverleafTier
                derivedStatus
              }
              trustedOrgs {
                organization {
                  id
                  name
                }
              }
              topParentOrganization {
                id
                name
                isTopParentOrganization
              }
              onboarding {
                memberCount
              }
              counts {
                hierarchyMembers
              }
              invitations {
                email
              }
              created
            }
          }
        }
      }
    }
  }
`;

const useRelayOrganizationSearch = ({
  userId,
  organizationId,
  search: _search = {},
  sort: _sort,
  first = 15,
  after,
  last,
  before,
  query = MY_ORGANIZATIONS_SEARCH_QUERY,
  performInitialSearch = true,
  ...rest
}) => {
  const staticVariables = {
    userId,
    organizationId,
    first,
    after,
    last,
    before,
  };

  // Track if initial search has been performed
  const initialSearchPerformedRef = React.useRef(false);

  const [search, setSearch] = React.useState({
    ..._search,
    text: _search?.text || "",
    organizationSearchFilters: {
      ..._search?.organizationSearchFilters || {},
    },
  });

  const [sort, setSort] = React.useState([{
    column: _sort?.column || SORT_COLUMNS.DEFAULT,
    order: _sort?.order || SORT_ORDER.ASC,
  }]);

  const {
    data,
    error,
    loading,
    fetchMore,
    refetch,
  } = useQuery(query, {
    variables: omitUndefined({
      ...staticVariables,
      search,
      sort,
    }),
    skip: !performInitialSearch && !initialSearchPerformedRef.current,
    fetchPolicy: "network-only",
    // nextFetchPolicy: "cache-first",
    ...rest
  });

  // Create stable debounced refetch function
  const debouncedRefetch = React.useMemo(
    () => debounce(1000)(refetch),
    [refetch]
  );

  // Perform initial search if configured
  React.useEffect(() => {
    if (performInitialSearch && !initialSearchPerformedRef.current) {
      initialSearchPerformedRef.current = true;
    }
  }, [performInitialSearch]);

  // Standardize data extraction
  const searchData = data?.organizationSearch;
  const organizations = searchData?.edges?.map(edge => edge?.node?.organization) || [];
  const pageInfo = searchData?.pageInfo || {};

  const handleFetchMore = (args = {}) => {
    if (pageInfo?.hasNextPage) {
      return fetchMore({
        variables: {
          ...staticVariables,
          search,
          sort,
          first: 50,
          after: pageInfo?.endCursor,
          ...args,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return {
            organizationSearch: {
              ...prev.organizationSearch,
              pageInfo: {
                ...fetchMoreResult.organizationSearch.pageInfo,
                total: prev.organizationSearch?.pageInfo?.total || fetchMoreResult.organizationSearch.pageInfo.total,
              },
              edges: uniqBy(
                "node.organization.id",
                [
                  ...prev.organizationSearch?.edges || [],
                  ...fetchMoreResult.organizationSearch.edges,
                ],
              ),
            },
          };
        },
      });
    }

    return undefined;
  };

  /**
   * @param {SORT_COLUMNS} column
   * @param {SORT_ORDER} order
   */
  const handleSort = (sortObject) => {
    const updatedSort = [sortObject.sort];

    setSort(updatedSort);

    return refetch({
      ...staticVariables,
      search,
      sort,
    });
  };

  /**
   * Enhanced search function that updates search text state
   * @param {object} query - Search parameters
   * @param {string} query.text - Search text
   * @param {object} query.organizationSearchFilters - Additional filters
   */
  const handleSearch = async (query) => {
    const { text, organizationSearchFilters } = query;

    const updatedSearch = { ...search };

    if (typeof text !== "undefined") {
      updatedSearch.text = text;
    }

    if (organizationSearchFilters === null) {
      updatedSearch.organizationSearchFilters = {};
    }
    else if (organizationSearchFilters) {
      updatedSearch.organizationSearchFilters = {
        ...updatedSearch.organizationSearchFilters,
        ...organizationSearchFilters,
      };
    }

    setSearch(updatedSearch);
    initialSearchPerformedRef.current = true;

    return debouncedRefetch({
      search: updatedSearch,
    });
  }

  return {
    data,
    error,
    fetchMore: handleFetchMore,
    loading,
    refetch: debouncedRefetch,
    search: handleSearch,
    sort: handleSort,
    organizations,
    pageInfo,
    currentSort: sort[0],
    currentSearch: search,
  };
}

const relayOrganizationSearchUtils = {
  SORT_COLUMNS,
  SORT_ORDER,
  SORT_VIEWS,
  getSortFromColumn,
  getSortFromName,
  MY_ORGANIZATIONS_SEARCH_QUERY,
  ADMIN_ORGANIZATIONS_SEARCH_QUERY,
};

export { useRelayOrganizationSearch, relayOrganizationSearchUtils };
