import {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback
} from "react";
import { gql, useQuery } from "@apollo/client";
import {
  Box,
  CheckBox,
  Grommet,
  Layer,
  Text
} from "grommet";
import { useLocalStorage } from "@/cloverleaf-ui/hooks";
import { themeStyle } from "@/cloverleaf-ui/theme";
import EmployeeSearch from "./EmployeeSearch";
import { useIsCloverleafEmployeeOrganizationCheckOnly_DO_NOT_USE_THIS } from "@/lib/hooks/useIsCloverleafEmployee";

const GET_EMPLOYEE_TOOLBOX_INFO = gql`
  query employeeToolboxInfo {
    currentUser {
      id
      avatar
      email
      hasAdminPermission
      isCloverleafAdmin
      organization {
        id
      }
      profile {
        id
      }
    }
  }
`;

export const EmployeeToolsContext = createContext({});

export const EmployeeToolsProvider = ({ children }) => {
  const [isOverwatch] = useLocalStorage("cl-emp-overwatch", false);
  const [isAuditingI18n, setIsAuditingI18n] = useLocalStorage("cl-emp-i18n-mask", false);
  const [isEditable, setIsEditable] = useLocalStorage("cl-emp-editable", false);
  const [isDemoMode, setIsDemoMode] = useLocalStorage("cl-emp-demo-mode", false);
  const [viewEmployeeToolbox, setViewEmployeeToolbox] = useState(false);
  const [viewEmployeeSearch, setViewEmployeeSearch] = useState(false);
  const isCloverleafEmployee = useIsCloverleafEmployeeOrganizationCheckOnly_DO_NOT_USE_THIS();

  const toggleAuditI18n = useCallback(() => {
    setIsAuditingI18n(prev => !prev);
  }, [setIsAuditingI18n]);

  const toggleEditable = () => {
    setIsEditable(!isEditable);
  };

  // In demo mode, we disable the employee tools
  const toggleDemoMode = () => {
    setIsDemoMode(!isDemoMode);
  };

  const toggleEmployeeToolbox = useCallback(() => {
    setViewEmployeeToolbox(prev => !prev);
  }, []);

  const toggleEmployeeSearch = useCallback(() => {
    setViewEmployeeSearch(prev => !prev);
  }, []);

  useEffect(() => {
    if (isCloverleafEmployee) {
      let queueOfKeys = [];
      let keypressTimeout;
      const handleKeyPress = (event) => {
        // command + e
        if (event.metaKey && event.key === "e") {
          toggleEmployeeToolbox();
        }

        // command + i
        if (event.metaKey && event.key === "i") {
          toggleAuditI18n();
        }

        if (event.metaKey && event.key === "Meta" && queueOfKeys.length && queueOfKeys[0] === "Meta") {
          toggleEmployeeSearch();
        }
        if (event.ctrlKey && event.key === "Control" && queueOfKeys.length && queueOfKeys[0] === "Control") {
          toggleEmployeeSearch();
        }

        queueOfKeys.push(event.key);
        clearTimeout(keypressTimeout);
        keypressTimeout = setTimeout(() => {
          queueOfKeys = [];
        }, 500);
      };

      window.addEventListener("keydown", handleKeyPress);

      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    } else if (isOverwatch) {
      /**
       * Non-Admin Permitted Functions
       * User can manually add cl-emp-overwatch in Local Storage to use these specific,
       * non-sensitive functions without being authenticated as a cloverleaf employee.
       */
      let queueOfKeys = [];
      let keypressTimeout;
      const handleKeyPress = (event) => {
        // command + i
        if (event.metaKey && event.key === "i") {
          toggleAuditI18n();
        }

        queueOfKeys.push(event.key);
        clearTimeout(keypressTimeout);
        keypressTimeout = setTimeout(() => {
          queueOfKeys = [];
        }, 500);
      };

      window.addEventListener("keydown", handleKeyPress);

      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [isCloverleafEmployee, isOverwatch, toggleAuditI18n, toggleEmployeeSearch, toggleEmployeeToolbox]);

  return (
    <EmployeeToolsContext.Provider
      value={{
        isAuditingI18n, toggleAuditI18n,
        isEditable, toggleEditable,
        isDemoMode, toggleDemoMode,
        toggleEmployeeToolbox,
        toggleEmployeeSearch,
      }}
    >
      {children}
      <Grommet theme={themeStyle}>
        {viewEmployeeToolbox && (
          <Layer
            onEsc={() => setViewEmployeeToolbox(false)}
            onClickOutside={() => setViewEmployeeToolbox(false)}
          >
            <EmployeeToolbox />
          </Layer>
        )}
        {viewEmployeeSearch && (
          <Layer
            onEsc={() => setViewEmployeeSearch(false)}
            onClickOutside={() => setViewEmployeeSearch(false)}
          >
            <EmployeeSearch />
          </Layer>
        )}
      </Grommet>
    </EmployeeToolsContext.Provider>
  );
};

const EmployeeToolbox = () => {
  const {
    isAuditingI18n,
    toggleAuditI18n,
    isEditable,
    toggleEditable,
    isDemoMode,
    toggleDemoMode,
  } = useContext(EmployeeToolsContext);

  const { data } = useQuery(GET_EMPLOYEE_TOOLBOX_INFO);

  return (
    <Box
      background="black"
      pad="small"
      gap="large"
      // style={{
      //   minWidth: "600px",
      // }}
    >
      <Text size="large">Cloverleaf Employee Toolbox</Text>
      <Box direction="row" gap="large">
        <Box direction="column">
          <Text>Page Info:</Text>
          <span><b>- User ID:</b> {data?.currentUser?.id}</span>
          <span><b>- Organization ID:</b> {data?.currentUser?.organization?.id}</span>
          <span><b>- Profile ID:</b> {data?.currentUser?.profile?.id}</span>
          <span><b>- Admin:</b> {data?.currentUser?.hasAdminPermission ? "✅" : "✖️"}</span>
          <span><b>- Super Admin:</b> {data?.currentUser?.isCloverleafAdmin ? "✅" : "✖️"}</span>
        </Box>
        <Box direction="column">
          <Box direction="row" gap="small">
            <Text>(cmd + i) Audit i18n Strings:&nbsp;</Text>
            <CheckBox
              checked={isAuditingI18n}
              onChange={() => {
                toggleAuditI18n();
              }}
            />
          </Box>
          <Box direction="row" gap="small">
            <Text>Content Edit Mode:&nbsp;</Text>
            <CheckBox
              checked={isEditable}
              onChange={() => {
                toggleEditable();
              }}
            />
          </Box>
          <Box direction="row" gap="small">
            <Text>Demo Mode:&nbsp;</Text>
            <CheckBox
              checked={isDemoMode}
              onChange={() => {
                toggleDemoMode();
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeToolbox;
