import { useEffect } from "react";
import { useRouter } from "next/router";
import { gql, useQuery } from "@apollo/client";
import mixpanel from "mixpanel-browser";
import { identify } from "@/lib";
import { sanitizeSearchParams } from "@/lib/sanitizeSearchParams";
import { sanitizeUrl } from "@/lib/sanitizeUrl";
import { analytics } from "@/lib/segment";

const GET_CURRENT_USER_DETAILS_FOR_ANALYTICS = gql`
  query getCurrentUserTraitsForAnalytics {
    currentUser {
      id
      email
      firstName
      lastName
      fullName
      isCandidate
      isLead
      intercomId
      organization {
        id
        name
      }
    }
  }
`;

function Analytics({ isAuthenticated }) {
  const router = useRouter();

  const { loading, data } = useQuery(GET_CURRENT_USER_DETAILS_FOR_ANALYTICS, {
    fetchPolicy: "cache-first",
    skip: !isAuthenticated,
  });

  useEffect(() => {
    if (!loading && data && data.currentUser) {
      const {
        id,
        email,
        firstName,
        lastName,
        fullName,
        isCandidate,
        isLead,
        organization,
        intercomId,
      } = data.currentUser;

      identify(id, {
        email,
        firstName,
        lastName,
        fullName,
        isCandidate,
        isLead,
        companyName: organization?.name,
      },
      {
        integrations: {
          Intercom: {
            user_hash: intercomId,
          }
        },
      });
    }
  }, [loading, data]);

  useEffect(() => {
    const handleRouteChange = () => {
      analytics.page(undefined, {
        search: sanitizeSearchParams(window.location.search),
        url: sanitizeUrl(window.location.href),
      });

      try {
        mixpanel.track_pageview({
          $current_url: sanitizeUrl(window.location.href),
          $referrer: sanitizeUrl(document.referrer),
        });
      } catch (error) {
        // Swallow mixpanel errors, may not be initialized yet.
      }
    };

    handleRouteChange();

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export { Analytics };
